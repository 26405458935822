export enum FormDataObjectType {
  ELEMENT = 'element',
  FILE = 'file',
}

export type MappedFormDataObject = {
  type: FormDataObjectType
  element: string
  value: any | any[]
}

export type MutationVariables = {
  elements: MappedFormDataObject[]
  files: MappedFormDataObject[]
}
