<template>
  
<FormKitLazyProvider config-file="true">
<FormKit
    ref="formKit"
    v-model="formData"
    type="form"
    :submit-label="computedSubmitLabel"
    :input-errors="inputErrors"
    :form-class="formClass"
    @submit="onSubmit"
  >
    <FormKitSchema :schema="schema" :data="formData" />
  </FormKit>
</FormKitLazyProvider>

</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { FormKitSchema } from '@formkit/vue'
import type { FormKitSchemaNode } from '@formkit/core'
import {
  type MutationVariables,
  type MappedFormDataObject,
  FormDataObjectType,
} from './../../types/webform'
import { populateFormData } from './../../helpers/webformMapper'
import { getSchemaBuilder } from './../../helpers/mappers'
import type {
  WebformFragment,
  WebformSubmissionResultFragment,
} from '#graphql-operations'

export type WebformSubmitHandler = (
  formData: Record<string, any>,
) => Promise<WebformSubmissionResultFragment>

const emit = defineEmits<{
  (e: 'success', data: { token?: string; id?: string }): void
}>()

const { buildChildren } = await getSchemaBuilder()

const formKit = ref(null)

const props = defineProps<{
  form: WebformFragment['form']
  confirmation: WebformFragment['confirmation']
  submitForm: WebformSubmitHandler
  submitLabel?: string
  formClass: string | string[]
  settings: WebformFragment['settings']
}>()

const webformItems = computed(() => props.form.items || [])

const schema = computed<FormKitSchemaNode[]>(() => {
  return buildChildren(props.form?.items || [])
})

const formData = ref(populateFormData(props.form?.items, {}))

const inputErrors = ref<Record<string, string[]>>({})

const computedSubmitLabel = computed(() => {
  if (props.submitLabel) {
    return props.submitLabel
  }
  for (let i = 0; i < webformItems.value.length; i++) {
    const item = webformItems.value[i]
    if (item && item.element?.__typename === 'WebformElementWebformActions') {
      return item.element.submitLabel
    }
  }

  return 'Submit'
})

/**
 * Map a form data value to a WebformSubmissionElement compatible value.
 */
function mapFormDataValue(v: any): string | string[] {
  if (Array.isArray(v)) {
    return v.map(mapFormDataValue).flat()
  } else if (v === true) {
    return '1'
  } else if (v === false) {
    return '0'
  } else if (v === undefined || v === null) {
    return ''
  } else if (typeof v === 'string') {
    return v
  } else if (typeof v === 'number') {
    return v.toString()
  }
  return ''
}

function mapFormDataObject(v: Record<string, any>): MappedFormDataObject[] {
  const result = Object.entries(v)
    .map(([element, valueRaw]) => {
      if (Array.isArray(valueRaw)) {
        return valueRaw
          .map((nestedItem, index) => {
            if (
              typeof nestedItem === 'object' &&
              !(nestedItem instanceof File) &&
              !(nestedItem.file instanceof File)
            ) {
              const newObj: Record<string, any> = {}
              Object.keys(nestedItem).forEach((key) => {
                const indexedKey = key.replace('[0]', `[${index}]`)
                newObj[indexedKey] = nestedItem[key]
              })
              return mapFormDataObject(newObj)
            }
            if (nestedItem && nestedItem.file instanceof File) {
              return {
                type: FormDataObjectType.FILE,
                element,
                value: nestedItem.file,
              }
            }
            const value = mapFormDataValue(nestedItem)
            return { element, value, type: FormDataObjectType.ELEMENT }
          })
          .flat()
      }
      if (
        typeof valueRaw === 'object' &&
        !Array.isArray(valueRaw) &&
        !(valueRaw instanceof File)
      ) {
        return mapFormDataObject(valueRaw)
      }
      if (valueRaw instanceof File) {
        return {
          type: FormDataObjectType.FILE,
          element,
          value: valueRaw,
        }
      }
      const value = mapFormDataValue(valueRaw)
      return { element, value, type: FormDataObjectType.ELEMENT }
    })
    .flat()

  return result
}

/**
 * Map the form data object to an array of WebformSubmissionElement objects.
 */
function mapFormData(v: Record<string, any>): MutationVariables {
  const items = mapFormDataObject(v)

  return items.reduce<MutationVariables>(
    (acc, v) => {
      if (v.type === FormDataObjectType.ELEMENT) {
        acc.elements.push(v)
      } else {
        acc.files.push(v)
      }
      return acc
    },
    { elements: [], files: [] },
  )
}

async function onSubmit(data: any) {
  try {
    const result = await props.submitForm!(mapFormData(data))
    inputErrors.value = (result?.validations || []).reduce<
      Record<string, string[]>
    >((acc, v) => {
      if (v.element && v.messages) {
        acc[v.element] = v.messages
      }
      return acc
    }, {})
    if (result.errors?.length || result.validations?.length) {
      return
    }
    emit('success', {
      id: result.submissionId,
      token: result.submissionToken,
    })
  } catch (e) {
    console.log(e)
  }
}
</script>
