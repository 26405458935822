import { type FormKitProps } from '@formkit/core'
import { nonNullable } from '#vuepal/helpers/type'
import type { WebformElementMapper } from './defineWebformElementMapper'
import {
  type WebformElementStatesFragment,
  type WebformItemFragment,
  WebformStateConditionTrigger,
  WebformStateLogic,
} from '#graphql-operations'

/**
 * Maps WebformStateConditionTrigger to a JavaScript comparison operator.
 */
const TRIGGER_OPERATOR_MAP: Partial<
  Record<WebformStateConditionTrigger, string>
> = {
  [WebformStateConditionTrigger.VALUE_IS]: '===',
  [WebformStateConditionTrigger.VALUE_IS_NOT]: '!==',
}

/**
 * Maps the WebformStateLogic enum to a JavaScript boolean operator.
 */
const LOGIC_OPERATOR_MAP: Partial<Record<WebformStateLogic, string>> = {
  [WebformStateLogic.AND]: '&&',
  [WebformStateLogic.OR]: '||',
  [WebformStateLogic.XOR]: '||',
}

/**
 * Map the WebformElementStates to FormKit conditions.
 *
 * Not all states are currently supported.
 */
function mapCondition(
  states?: WebformElementStatesFragment,
): Partial<FormKitProps> {
  const conditionProps: Partial<FormKitProps> = {}

  if (states?.visible && states.visible.logic) {
    const operator = LOGIC_OPERATOR_MAP[states.visible.logic]
    if (operator) {
      const conditions = (states?.visible.conditions || [])
        .map((condition) => {
          const { trigger, field, fieldValue } = condition
          if (trigger === WebformStateConditionTrigger.FILLED) {
            return `$${field}`
          }

          const comparisonOperator = trigger && TRIGGER_OPERATOR_MAP[trigger]

          if (field && comparisonOperator) {
            return `$${field} ${comparisonOperator} '${fieldValue}'`
          }

          return null
        })
        .filter(nonNullable)
        .join(` ${operator} `)
      if (conditions) {
        conditionProps.if = conditions
      }
    }
  }

  return conditionProps
}

function mapSharedProps(item: WebformItemFragment): Partial<FormKitProps> {
  const { name, required, title, help, element, states } = item

  const label = title
  const validation = []

  if (required || states?.required) {
    validation.push(['required'])
  }

  const props: Partial<FormKitProps> = {
    name,
    key: name,
    label,
    validation,
    help,
    __raw__sectionsSchema: {
      help: {
        children: '',
        attrs: {
          innerHTML: '$help',
        },
      },
    },
    ...mapCondition(item.states),
    readonly: element && 'readonly' in element ? !!element.readonly : false,
  }

  return props
}

export const getSchemaBuilder = async () => {
  const imports = import.meta.glob('./mappers/*.ts')
  const mappers: WebformElementMapper<any>[] = await Promise.all(
    Object.values(imports).map((v) => {
      return v().then((module: any) => {
        return module.default
      })
    }),
  )

  // Create the map of GraphQL element type => mapper.
  const mapperTypeMap = mappers.reduce<
    Record<string, WebformElementMapper<any>>
  >((acc, mapper) => {
    mapper.types.forEach((type) => {
      acc[type] = mapper
    })

    return acc
  }, {})

  const buildChildren = (items: WebformItemFragment[]) => {
    return items
      .map((item) => {
        const typename = item.element?.__typename
        if (!typename) {
          return null
        }
        const mapper = mapperTypeMap[typename]
        if (!mapper) {
          return null
        }

        const mapped = mapper.build(item as any, ctx as any)
        if (!mapped) {
          return null
        }

        // Props shared for every form item.
        const sharedProps = mapSharedProps(item)
        const sharedValidation = sharedProps.validation || []
        const mappedValidation =
          mapped && 'validation' in mapped ? mapped.validation || [] : []
        return {
          ...sharedProps,
          ...mapped,
          validation: [...sharedValidation, ...mappedValidation],
        }
      })
      .filter(nonNullable)
  }

  const ctx = {
    buildChildren,
  }

  return { buildChildren }
}
