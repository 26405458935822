import type { WebformItemFragment } from '#graphql-operations'

/**
 * Given the array of WebformFormItem objects build the FormKit data
 * definition.
 */
export function populateFormData(
  items: WebformItemFragment[] | null = [],
  data: Record<string, any>,
) {
  items?.forEach((item) => {
    const { name, defaultValue } = item
    if (name) {
      data[name] = defaultValue || ''
    }
    if (item.element && 'items' in item.element) {
      populateFormData(item.element.items, data)
    }
  })

  return data
}
