<template>
  <div>
    <VuepalWebform
      v-bind="webform"
      :submit-form="submitForm"
      :submit-label="$texts('submit', 'Senden')"
      form-class="grid"
      @success="onSuccess"
    />
  </div>
</template>

<script lang="ts" setup>
import type {
  WebformSubmissionFile,
  WebformSubmissionResultFragment,
  WebformFragment,
} from '#graphql-operations'
import type { MutationVariables } from '~/layers/webform/types/webform'

const language = useCurrentLanguage()

const props = defineProps<{
  webform: WebformFragment
  sourceEntityId?: string
  sourceEntityType?: string
}>()

function submitForm(input: MutationVariables) {
  if (!props.webform?.id) {
    return
  }
  const formdata = new FormData()

  const elements = input.elements.map((v) => {
    return {
      element: v.element,
      value: v.value,
    }
  })

  // File stuff.
  const files: WebformSubmissionFile[] = []
  const map: Record<string, string[]> = {}

  input.files.forEach((v, index) => {
    files.push({
      element: v.element,
      file: null,
    })
    map[index.toString()] = [`variables.files.${index}.file`]
    formdata.append(index.toString(), v.value)
  })

  const variables: any = {
    id: props.webform.id,
    elements,
    files,
    sourceEntityType: props.sourceEntityType,
    sourceEntityId: props.sourceEntityId,
  }

  formdata.append('variables', JSON.stringify(variables))
  formdata.append('map', JSON.stringify(map))
  formdata.append('webform_id', props.webform.id || '')

  return $fetch<WebformSubmissionResultFragment | null>('/api/webform-submit', {
    method: 'POST',
    body: formdata,
    query: {
      language: language.value,
    },
  })
}

const router = useRouter()

function onSuccess({ id, token }: { id?: string; token?: string }) {
  if (id && token) {
    router.push({
      name: 'webform-confirmation',
      params: {
        id,
      },
      query: {
        token,
        webformId: props.webform.id,
      },
    })
  }
}
</script>
